<template>
  <div>
    <div class="card-box">
      <div class="phone">
        <div class="form-title">{{ tableName }}</div>
        <v-form-render
          v-if="formJson.widgetList.length"
          :form-json="formJson"
          :form-data="formData"
          :option-data="optionData"
          :global-dsv="globalDsv"
          ref="vFormRef"
        ></v-form-render>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </div>
    </div>
    <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="手机端" name="1">
        <div class="card-box">
          <div class="phone">
            <div class="form-title">{{ tableName }}</div>
            <v-form-render
              v-if="formJson.widgetList.length"
              :form-json="formJson"
              :form-data="formData"
              :option-data="optionData"
              :global-dsv="globalDsv"
              ref="vFormRef"
            ></v-form-render>
            <el-button type="primary" @click="submitForm">提交</el-button>
          </div>
          <div class="qrcode-box">
            <div class="qrcode" id="qrCode" ref="qrCodeDiv"></div>
            <div class="title">扫码在手机端查看</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="电脑端" name="2">
        <div class="phone computer">
          <div class="form-title">{{ tableName }}</div>
          <v-form-render
            v-if="formJson.widgetList.length"
            :form-json="formJson"
            :form-data="formData"
            :option-data="optionData"
            :global-dsv="globalDsv"
            ref="vFormRef"
          ></v-form-render>
          <el-button type="primary" @click="submitForm">提交</el-button>
        </div>
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
import { getUrl } from "@/utils/api";
import axios from "axios";
import qs from 'qs'
export default {
  data() {
    return {
      activeName: "1",
      id: "",
      tableName: "",
      /* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
      formJson: {
        widgetList: [],
        formConfig: {},
      },
      formData: {},
      optionData: {},
      globalDsv: {
        isToken: 1,
      },
      url: "123",
    };
  },
  mounted() {
    // this.bindQRCode();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      let url = getUrl() + "/vfTableInfo/info";
      axios
        .get(url, {
          params: { id: this.id },
          headers: {
            token: "localStorage.token",
            // 'Content-Type': 'application/x-www-form-urlencoded'
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            this.tableName = res.data.data.tableName;
            if (res.data.data && res.data.data.tableContent) {
              this.formJson = JSON.parse(res.data.data.tableContent);
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1500,
              onClose: () => {},
            });
          }
        })
        .catch(() => {
          // this.$message.error(
          //   this.i18nt("designer.hint.loadFormTemplateFailed") + ":" + error
          // );
        });
    },
    // 提交信息接口
    submitData(formData, optionData) {
      let patientInfo = {};
      if (localStorage.patientInfo) {
        patientInfo = JSON.parse(localStorage.patientInfo);
      }
      let params = {};
      let arr = [];
      console.log(formData);
      if (formData) {
        this.$nextTick(() => {
          let typeArr = this.$refs.vFormRef.getFieldWidgets();
          if (typeArr.length) {
            let obj;

            for (let key in formData) {
              obj = {
                relationId: this.$route.query.relationId,
                infoId: this.$route.query.id,
                teamId: this.$route.query.teamId,
                patientId: this.$route.query.patientId,
                taskId: this.$route.query.infoId,
                cellId: key,
                fillContent: formData[key],
                fillType: "",
              };
              typeArr.forEach((ele) => {
                if (ele.name == key) {
                  obj.fillType = ele.type;
                }
              });
              arr.push(obj);
            }
          }
          if (arr.length) {
            params.list = JSON.stringify(arr);
          }
          // console.log(params.list);
          // return;

          let params2 = {
            isAnswer: 1,
            ids: this.$route.query.infoId,
          };
          this;
          this.$http({
            url: this.$http.adornUrl("/followUp/updateIsAnswerById"),
            method: "post",
            data: qs.stringify(params2),
          }).then(({ data }) => {

          });
          
          // addWriteInfo(qs.stringify(params)).then((res) => {
          //   if (res.data.status) {
          //     // if (route.query.from == "richeng") {
          //     //   confirm();
          //     // }

          //     // Toast("提交成功");
          //     router.go(-1);
          //   } else {
          //     // Toast(res.data.msg);
          //   }
          // });
        });
      }
    },
    submitForm() {
      this.$refs.vFormRef
        .getFormData()
        .then((formData) => {
          // Form Validation OK
          this.submitData(formData);
        })
        .catch((error) => {
          // Form Validation failed
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode-box {
  position: absolute;
  bottom: 0;
  right: 0;
  .title {
    text-align: center;
    padding-top: 10px;
  }
}
.form-title {
  font-size: 20px;
  color: #0099ff;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px 0 40px;
}
.phone {
  width: 100%;
  // min-height: 667px;
  // border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px;
  margin: 0 auto;
  overflow: auto;
  text-overflow: ellipsis;
  background: none;

  &.computer {
    width: 70%;
  }
  .el-button {
    margin-top: 50px;
    width: 100%;
  }
}
</style>
